import React, { ReactNode } from 'react';
import { MRT_ColumnDef, MRT_TableOptions, useMaterialReactTable } from 'material-react-table';
import { InvoiceDto } from 'src/generated/services/TFinancialApi';
import { formatDateString } from 'src/utils/date';

import { beautifyEnum, SimpleFormatNumber, removeQuotes } from 'src/utils/utility';
import DetailCell from '../../organisms/dashboard/DetailsCell';
import StatusChip from '../../organisms/dashboard/StatusChip';
import TableActions from '../../organisms/dashboard/TableActions';
import ReceiveSquare from '../../../../assets/images/icons/receive-square.svg';
import SendSquare from '../../../../assets/images/icons/send-square.svg';
import ArrangeSquare from '../../../../assets/images/icons/arrange-square.svg';

export const useTableInvoice = (tableOptions: MRT_TableOptions<InvoiceDto>) =>
  useMaterialReactTable({
    enableSorting: false,
    enablePinning: false,
    enableRowDragging: false,
    enableRowSelection: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSortingRemoval: false,
    enableEditing: true,
    editDisplayMode: 'table',
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    ...tableOptions,
  });

export const shipmentTypeIcons: Record<string, ReactNode> = {
  IMPORT: <img src={ReceiveSquare} alt='ReceiveSquare' style={{ width: '24px', height: '25px' }} />,
  EXPORT: <img src={SendSquare} alt='SendSquare' style={{ width: '24px', height: '25px' }} />,
  MULTI: <img src={ArrangeSquare} alt='ArrangeSquare' style={{ width: '24px', height: '25px' }} />,
};

export const useDefaultColumns = (currencyReference: string, dateReference: string): MRT_ColumnDef<InvoiceDto>[] => [
  {
    enableEditing: false,
    enableSorting: false,
    accessorFn: (row: InvoiceDto) => shipmentTypeIcons[row.aggregateShipmentType],
    accessorKey: 'aggregateShipmentType',
    header: ' ',
    size: 90,
    Cell: ({ cell }) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        {(cell.getValue() as ReactNode) || null}
      </div>
    ),
  },
  {
    enableEditing: false,
    enableSorting: true,
    accessorFn: undefined,
    accessorKey: 'invoiceNumber',
    header: 'INVOICE NO.',
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: true,
    accessorKey: 'date',
    accessorFn: (row) => {
      let date: string;
      switch (dateReference) {
        case 'PICKUP_DATE':
          date = row.pickupDate;
          break;
        case 'DELIVERY_DATE':
          date = row.deliveryDate;
          break;
        case 'INVOICE_DATE':
          date = row.invoiceDate;
          break;
        case 'INVOICE_DUE_DATE':
          date = row.invoiceDueDate;
          break;
        case 'SUBMISSION_DATE':
          date = row.submissionDate;
          break;
        default:
          throw new Error('Invalid date reference');
      }
      return formatDateString(date);
    },
    header: 'DATE',
    Header: (
      <div style={{ textAlign: 'center' }}>
        <div>DATE</div>
        <div style={{ fontSize: '0.75rem', color: '#808080', fontWeight: 'lighter' }}>{beautifyEnum(dateReference)}</div>
      </div>
    ),
    size: 20,
  },
  {
    enableEditing: false,
    enableSorting: true,
    accessorKey: 'shipments',
    accessorFn: (row) => {
      let result: string | number | null | undefined;
      if (row?.shipments && row?.shipments.length > 0) {
        if (row?.shipments.length === 1) {
          result = row?.shipments[0]; // Single shipment
        } else {
          result = `${row?.shipments.length} Shipments`; // Multiple shipments
        }
      } else {
        result = '-'; // Fallback if no shipments
      }
      return removeQuotes(result); // Use the utility function to remove quotes
    },
    header: 'SHIP NO.',
    size: 150,
  },
  {
    enableEditing: false,
    enableSorting: true,
    accessorKey: 'tradeLanes',
    accessorFn: (row) => {
      if (row?.tradeLanes && row?.tradeLanes.length > 0) {
        if (row?.tradeLanes.length === 1) {
          return row?.tradeLanes[0].replace(/:/g, ' / ');
        }
        return `${row?.tradeLanes.length} Trade Lanes`;
      }
      return '-';
    },
    header: 'TRADE LANE',
    size: 150,
  },
  {
    enableEditing: false,
    enableSorting: true,
    accessorKey: 'services',
    accessorFn: (row) => {
      if (row?.services && row?.services.length > 0) {
        if (row?.services.length === 1) {
          return row?.services[0];
        }
        return `${row?.services.length} Services`;
      }
      return '-';
    },
    header: 'SERVICE',
    size: 150,
  },
  {
    enableEditing: false,
    enableSorting: true,
    accessorKey: 'totalAmount',
    accessorFn: (row) => {
      if (row?.totalAmount) {
        return SimpleFormatNumber(row?.totalAmount, currencyReference === 'JPY', 2);
      }
      return '-';
    },
    header: 'TOTAL AMOUNT',
    Header: (
      <div style={{ textAlign: 'center' }}>
        <div>TOTAL AMOUNT</div>
        <div style={{ fontSize: '0.75rem', color: '#808080', fontWeight: 'lighter' }}>{currencyReference}</div>
      </div>
    ),
    size: 150,
  },
  {
    enableEditing: false,
    enableSorting: true,
    accessorKey: 'status',
    accessorFn: (row) => <StatusChip status={row.status} />,
    header: 'STATUS',
    size: 150,
  },
  {
    enableEditing: false,
    enableSorting: false,
    accessorKey: undefined,
    accessorFn: (row) => {
      if (row.userResolutionStatus === 'UNDER_REVIEW') {
        return beautifyEnum('UNDER_REVIEW');
      }
      return <DetailCell data={row} />;
    },
    header: 'PENDING ITEMS',
    size: 150,
  },
  {
    enableEditing: false,
    accessorFn: (row) => <TableActions data={row as InvoiceDto} />,
    accessorKey: 'actions',
    header: ' ',
    enableSorting: false,
    size: 90,
  },
];
